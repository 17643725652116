// Color variables
$white: #ffffff;
$background: #f8f5fe;
$background2: rgba(255, 255, 255, 0.4);
$light: #fcfaff;
$lightgrey: #d3d3d3;
$violet-100: #522699;
$violet-80: rgba(82, 38, 153, 0.6);
$violet-60: #dbcbf5;
$violet-40: rgba(219, 203, 245, 0.6);
$violet-20: #f3eefc;
$blue: #14aaff;
$yellow: #ffbb00;
$lightrose: #ffcece;
$rose: #ff9d9d;
$red: #dc3545;
$lightpurple: #a784f3;
$lightblue: #7fd7ff;

// System variables
$header-height: 137px;
$lab-sidebar-width: 433px;
$lab-sidebar-width-3col: 337px;
$lab-sidebar-hidden-width: 80px;
$lab-sidebar-padding: 2rem;
@mixin wSmall {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin w1200 {
  @media (min-width: 1200px) and (max-width: 1299px) {
    @content;
  }
}
@mixin w1300 {
  @media (min-width: 1300px) {
    @content;
  }
}
@function labSidebarWidth($size) {
  @if $size == 'xl' {
    @return $lab-sidebar-width;
  }
  @if $size == 'lg' {
    @return $lab-sidebar-width-3col;
  }
}

// Variable overrides
$body-bg: transparent;
$body-color: $violet-100;

$theme-colors: (
  primary: $background,
);
